<template>
  <section>
    <div
      class="d-flex justify-content-center justify-content-lg-end justify-content-md-end justify-content-xl-end mb-2"
    >
      <router-link to="/home">
        <b-button variant="primary">Visão Geral</b-button>
      </router-link>
    </div>
    <b-row class="col-md-12 col-sm-12 d-flex justify-content-center">
      <div class="col-md-2" v-for="(item, index) in items" :key="index">
        <b-card bg-variant="light">
          <div class="d-flex flex-column align-items-center">
            <h4 style="text-align:center;">
              <b style="color:#000">{{ item.value }}</b>
            </h4>
            <h6 style="text-align:center;">{{ item.label }}</h6>
            <img
              height="20px"
              :src="require(`../assets/img/${item.img}.svg`)"
            />
          </div>
        </b-card>
      </div>
    </b-row>
    <hr />
    <div class="row">
      <div class="col-md-12">
        <h5>Boletim Covid-19 nos Municípios</h5>
        <div class="col-md-3">
          <vue-excel-xlsx
            :data="valueTable"
            :columns="column"
            :filename="'Dados COVID-19 - Ceará'"
            :sheetname="'Dados COVID-19 - Ceará'"
            class="form-control mb-2"
          >
            <b-icon-download />
            <span class="ml-2">Download</span>
          </vue-excel-xlsx>
        </div>
        <table>
          <thead>
            <tr>
              <th>
                <b>Município</b>
              </th>
              <th>
                <b>Confirmados</b>
              </th>
              <th>
                <b>Suspeitos</b>
              </th>
              <th>
                <b>Descartados</b>
              </th>
              <th>
                <b>Óbitos</b>
              </th>
              <th>
                <b>Leitos enfermaria</b>
              </th>
              <th>
                <b>Leitos uti</b>
              </th>
              <th>
                <b>Respiradores</b>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in valueTable" :key="index">
              <td>
                <router-link :to="{ path: `cidade/${item.city_id}` }">{{
                  item.name
                }}</router-link>
              </td>
              <td>{{ item.confirmed }}</td>
              <td>{{ item.suspicious }}</td>
              <td>{{ item.discarded }}</td>
              <td>{{ item.lethal }}</td>
              <td>{{ item.available_beds }}</td>
              <td>{{ item.available_uti }}</td>
              <td>{{ item.available_breathers }}</td>
            </tr>
          </tbody>
        </table>
        <div v-if="chartData.length > 0">
          <div class>
            <h5>Casos de COVID-19 por municípios</h5>
            <!-- IncidentsChartCity -->
            <GChart
              type="ColumnChart"
              :data="chartData"
              :options="chartOptions"
              :style="{ width: '100%' }"
            />
            <!-- <incidents-chart-city /> -->
          </div>
          <hr />
        </div>
        <h5>Casos de COVID-19 no brasil</h5>
        <div class="row col-md-12 d-flex justify-content-between">
          <h6>Confirmados</h6>
          <h6 class="ml-5" style="color: #f80000;">
            {{ this.chartDataBrasil.confirmed }}
          </h6>
        </div>
        <div class="row col-md-12 d-flex justify-content-between">
          <h6>Curados</h6>
          <h6 class="ml-5" style="color: #08bb68;">
            {{ this.chartDataBrasil.recovered }}
          </h6>
        </div>
        <div class="row col-md-12 d-flex justify-content-between">
          <h6>Óbitos</h6>
          <h6 class="ml-5" style="color: #000;">
            {{ this.chartDataBrasil.deaths }}
          </h6>
        </div>
        <hr />
      </div>
      <hr />
      <!-- Casos ativos -->
    </div>
  </section>
</template>
<script>
import Service from "../services";
import Axios from "axios";
// import IncidentsChartCity from "../components/Charts/IncidentsChartCity";
export default {
  components: {
    // IncidentsChartCity,
  },
  data() {
    return {
      column: [
        { label: "Município", field: "name" },
        { label: "Confirmados", field: "confirmed" },
        {
          label: "Suspeitos",
          field: "suspicious"
        },
        { label: "Descartados", field: "discarded" },
        { label: "Óbitos", field: "lethal" },
        { label: "Leitos enfermaria", field: "available_beds" },
        { label: "Leitos uti", field: "available_uti" },
        { label: "Respiradores", field: "available_breathers" }
      ],
      chartOptions: {
        colors: ["#DDE326", "#F92323"],
        chart: {
          title: "Company Performance",
          subtitle: "Sales, Expenses, and Profit: 2014-2017"
        },
        legend: {
          position: "bottom"
        }
      },
      items: [
        {
          label: "Confirmados",
          img: "line-red",
          value: 0
        },
        {
          label: "Suspeitos",
          img: "line-yellow",
          value: 0
        },

        {
          label: "Descartados",
          img: "line-grey",
          value: 0
        },
        {
          label: "Óbitos",
          img: "line-black",
          value: 0
        }
      ],
      valueTable: [],
      chartDataBrasil: {},
      chartData: []
    };
  },
  mounted() {
    Axios.get(
      "https://covid19-brazil-api.now.sh/api/report/v1/brazil",
      {}
    ).then(res => {
      this.chartDataBrasil = res.data.data;
    });
    const request = new Service();
    request.get("statistics/cities").then(res => {
      let aux = [];

      let chartData = [["City", "Suspeitos", "Confirmados"]];
      res.forEach(e => {
        aux.push({
          city_id: e.city_id,
          name: e.name,
          confirmed:
            Number(e.c_qty_isolated) +
            Number(e.c_qty_interned) +
            Number(e.c_qty_recovered) +
            Number(e.c_qty_lethal),
          suspicious: Number(e.s_qty_isolated) + Number(e.s_qty_interned),
          discarded: e.qty_discarded,
          lethal: e.c_qty_lethal,
          available_beds: e.available_beds,
          available_uti: e.available_uti,
          available_breathers: e.available_breathers
        });
        let a =
          Number(e.c_qty_isolated) +
          Number(e.c_qty_interned) +
          Number(e.c_qty_recovered) +
          Number(e.c_qty_lethal);
        let b = Number(e.s_qty_isolated) + Number(e.s_qty_interned);
        let arr = [e.name, b, a];
        chartData.push(arr);
      });
      this.valueTable = aux;
      this.chartData = chartData;
    });
    request.get("statistics/general").then(res => {
      this.items = [
        {
          label: "Confirmados",
          img: "line-red",
          value:
            Number(res.qty_confirmed) +
            Number(res.qty_lethal) +
            Number(res.qty_recovered)
        },
        {
          label: "Suspeitos",
          img: "line-yellow",
          value: Number(res.qty_suspicious)
        },

        {
          label: "Descartados",
          img: "line-grey",
          value: res.qty_discarded
        },
        {
          label: "Curados",
          img: "line-green",
          value: res.qty_recovered
        },
        {
          label: "Óbitos",
          img: "line-black",
          value: res.qty_lethal
        }
      ];
    });
  }
};
</script>
<style scoped>
.card {
  border-radius: 10px;
  border-width: 0px;
}

#floating {
  padding: 0 10px 0 10px;
  background: #132644;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  z-index: 1000 !important;
  position: absolute;
  top: 95px;
  right: 45px;
}
table {
  width: 100%;
  border-collapse: collapse;
}
/* Zebra striping */

th {
  background: #ddd;
  color: #000;
  font-weight: bold;
}
td,
th {
  border: none;

  padding: 6px;
  border-bottom: 1px solid #ccc;
  text-align: left;
}
@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Force table to not be like tables anymore */
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    border-radius: 2px;
    display: block;
  }
  tr {
    margin: 10px 0;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
    margin-top: 10px;
  }

  tr {
    border: 1px solid #ccc;
  }

  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
    background: #eee;
  }

  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }

  /*
	Label the data
	*/
  td:nth-of-type(1):before {
    content: "Município";
  }
  td:nth-of-type(2):before {
    content: "Confirmados";
  }
  td:nth-of-type(3):before {
    content: "Suspeitos";
  }

  td:nth-of-type(4):before {
    content: "Descartados";
  }

  td:nth-of-type(5):before {
    content: "Óbitos";
  }
  td:nth-of-type(6):before {
    content: "Leitos enfermaria";
  }
  td:nth-of-type(7):before {
    content: "Leitos uti";
  }

  td:nth-of-type(8):before {
    content: "Respiradores";
  }
}
</style>
